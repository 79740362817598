import React, { useState } from 'react';
import { FaEyeSlash } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
export const Login = () => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword((prev) => !prev);}
  return (
   <>
  <main className="mx-auto flex min-h-screen w-full items-center justify-center bg-gray-900 text-white">
  <section className="flex w-[30rem] flex-col space-y-10">
    <div className="text-center text-4xl font-medium">Log In</div>
    <div className="w-full transform border-b-2 bg-transparent text-lg duration-300 focus-within:border-indigo-500">
      <input type="text" placeholder="Email or Username" className="w-full px-2 text-sm py-2 border-none bg-transparent outline-none placeholder:italic focus:outline-none" />
    </div>
    <div className="w-full transform border-b-2 bg-transparent text-lg duration-300 focus-within:border-indigo-500">
      <div className="flex items-center">
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          className="w-full border-none px-2 text-sm bg-transparent py-2 outline-none placeholder:italic focus:outline-none"
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="ml-2"
        >
          {showPassword ? (
            <FaEyeSlash className="w-5 h-5 text-gray-500" />
          ) : (
            <IoEyeSharp  className="w-5 h-5 text-gray-500" />
          )}
        </button>
      </div>
    </div>
 

    <button className="transform rounded-sm bg-indigo-600 py-2 font-bold duration-300 hover:bg-indigo-400">LOG IN</button>
    <a href="#" className="transform text-center font-semibold text-gray-500 duration-300 hover:text-gray-300 text-sm">FORGOT PASSWORD?</a>
    <p className="text-center text-sm">
    Don’t have an account?
      <a href="#" className="font-medium text-indigo-500 underline-offset-4 hover:underline ml-2 text-sm"> Sign up</a>
    </p>
  </section>
</main>

   </>
  )
}
