import React, { useState } from "react";

export const HeroSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      sub_title: "Grow Your Brand with ",
      title: "FinDocs",
      description:
        "Our team of seasoned franchise consultants has the knowledge and experience to help you grow your brand. From strategic planning to operational guidance, we provide tailored solutions to ensure long-term success. Let us turn your business into a thriving franchise network, while you focus on what you do best.",
    },
    {
      sub_title: "Scale Your Business, ",
      title: "Maximize Your Profits",
      description:
        "Your business, your way. We offer tailored franchise solutions to meet your specific needs. Whether you're a startup or an established business, we can help you achieve your franchising goals. Our proven strategies and hands-on support ensure sustainable growth. With us, you’ll unlock new markets and scale with confidence, while maximizing profitability at every stage.",
    },
    {
      sub_title: "Expand Your ",
      title: "Reach",
      description:
        "Our expert guidance will help you navigate complex international markets and achieve global success.",
    },
  ];

  const scrollCarousel = (direction) => {
    const totalSlides = slides.length;
    const newIndex = (currentIndex + direction + totalSlides) % totalSlides;
    setCurrentIndex(newIndex);
  };

  return (
    <section className="bg-black">
      <div className="relative lg:container w-full mx-auto lg:py-10 py-4 px-4 sm:px-6 lg:px-8">
        <div
          className="absolute inset-0 bg-cover bg-center transform -scale-x-100"
          style={{ backgroundImage: `url('/closeup.jpg')` }} >
          <div className="absolute inset-0 bg-black opacity-80"></div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 items-center sm:gap-8 gap-0 relative z-10">
          <div className="relative w-full mx-auto">
            <div className="w-full mx-auto">
              <div className="overflow-hidden relative rounded-lg">
                <div
                  className="flex transition-transform duration-500 ease-in-out"
                  style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                  {slides.map((item, index) => (
                    <div
                      className="min-w-full flex justify-center items-center" 
                      key={index}
                    >
                      <div className="text-white text-center lg:px-24 md:px-0">
                        <p className="sm:text-2xl text-lg font-semibold mb-2 text-[#357ace]">{item.sub_title}</p>
                       
                        <h1 className="sm:text-5xl text-2xl font-bold text-[#357ace] mt-2">{item.title}</h1>
                        <h1 className="text-md mt-4 mb-20 text-[13px]">{item.description}</h1>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Navigation buttons */}
              <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex justify-center space-x-4">
                <button
                  className="p-3 bg-gray-700 bg-opacity-50 rounded-lg text-white hover:bg-opacity-75 focus:outline-none"
                  onClick={() => scrollCarousel(-1)}
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 19l-7-7 7-7"
                    ></path>
                  </svg>
                </button>
                <button
                  className="p-3 bg-gray-700 bg-opacity-50 rounded-lg text-white hover:bg-opacity-75 focus:outline-none"
                  onClick={() => scrollCarousel(1)}
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="mt-12 md:mt-0">
            <img
              src="/business.jpg"
              alt="business Image"
              className="object-cover rounded-lg shadow-md opacity-60"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
