
   import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
const events = [
    {
      title: "Absolutely recommended!",
      img: "https://images.unsplash.com/photo-1472491235688-bdc81a63246e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHwxfHxjYXR8ZW58MHwwfHx8MTcyMTgyMjE3OXww&ixlib=rb-4.0.3&q=80&w=1080",
    },
    {
      title: "Absolutely recommended!",
      img: "https://images.unsplash.com/photo-1478098711619-5ab0b478d6e6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHw1fHxjYXR8ZW58MHwwfHx8MTcyMTgyMjE3OXww&ixlib=rb-4.0.3&q=80&w=1080",
    },
   
    {
      title: "Absolutely recommended!",
      img: "https://images.unsplash.com/photo-1515002246390-7bf7e8f87b54?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHwxM3x8Y2F0fGVufDB8MHx8fDE3MjE4MjIxNzl8MA&ixlib=rb-4.0.3&q=80&w=1080",
    },
    {
      title: "Absolutely recommended!",
      img: "https://images.unsplash.com/photo-1511044568932-338cba0ad803?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHwyfHxjYXR8ZW58MHwwfHx8MTcyMTgyMjE3OXww&ixlib=rb-4.0.3&q=80&w=1080",
    },
    {
      title: "Absolutely recommended!",
      img: "https://images.unsplash.com/photo-1475518112798-86ae358241eb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHwxMHx8Y2F0fGVufDB8MHx8fDE3MjE4MjIxNzl8MA&ixlib=rb-4.0.3&q=80&w=1080",
    },
    {
      title: "Absolutely recommended!",
      img: "https://images.unsplash.com/photo-1498100152307-ce63fd6c5424?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHwxMXx8Y2F0fGVufDB8MHx8fDE3MjE4MjIxNzl8MA&ixlib=rb-4.0.3&q=80&w=1080",
    },
  ];
  
export const Events = () => {
  return (
    <>
 <div className="py-10 bg-[#05090f] sm:px-4 lg:px-0 px-4">
  <div className="p-[2px] rounded-lg shadow-sm overflow-hidden">
  <div className="sm:text-center mb-5">
        <h2 id="platform" className="relative text-center pb-4 font-display text-2xl font-bold tracking-tight text-gray-100 sm:text-3xl inline-block">
         Events Highlights
            <span className="absolute left-1/2 bottom-0 h-1 mb-2 w-full transform -translate-x-1/2 bg-gradient-to-r from-[#184277] to-yellow-900"></span>
          </h2>
        </div>
    <div className="relative z-10 sm:p-6 rounded-lg">
      <Swiper
        spaceBetween={30}
        loop={true}
        grabCursor={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        modules={[Autoplay]}
        className="w-full"
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 2, 
          },
          768: {
            slidesPerView: 3, 
          },
          1024: {
            slidesPerView: 3, 
          },
          1280: {
            slidesPerView: 4, 
          },
        }}
      >
       
       <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6">
  {events.map((item, index) => (
    <SwiperSlide
      key={index}
      className="flex flex-col justify-center items-center gap-4 bg-gray-800 rounded-lg hover:shadow-lg transition-shadow duration-300"
    >
      <img
        alt={item.title}
        src={item.img}
        className="object-contain w-full transition-all duration-300 ease-in-out filter grayscale opacity-100 hover:grayscale-0 hover:opacity-75"
      />
    </SwiperSlide>
  ))}
</div>

      </Swiper>
    </div>
  </div>
</div>



    </>
  );
};

   
