import React from "react";

export const Service = () => {
  const services = [
    {
      title: "Franchise Development",
      description:
        " Turn your business into a thriving franchise.",
    },
    {
      title: "Investor Matchmaking",
      description:
        "Find the ideal franchise opportunity for your goals.",
    },
    {
      title: "Market Analysis",
      description:
        "Make informed decisions with our in-depth research.",
    },
    {
      title: "Compliance Navigation",
      description:
        " Stay ahead with our legal and regulatory expertise.",
    },
  ];

  return (
    <div className="bg-black">
      <div className="max-w-6xl mx-auto py-8 sm:px-4 px-4 lg:px-0">
        <div className="lg:text-center lg:mb-8 mb-3">
          <h2 id="our-services" className="relative text-center pb-4 font-display text-2xl font-bold tracking-tight text-gray-100 sm:text-3xl inline-block">
            Our Core Services
            <span className="absolute left-1/2 bottom-0 h-1 mb-2 w-full transform -translate-x-1/2 bg-gradient-to-r from-[#184277] to-yellow-900"></span>
          </h2>
        </div>
        <main className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="relative p-[2px] rounded-lg shadow-sm overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-b from-[#184277] to-yellow-900 opacity-50"></div>
              <div className="relative z-10 bg-black p-6 rounded-lg">
                <p className="font-heading text-base sm:text-lg leading-6 cursor-pointer font-semibold text-gray-200 relative inline-block after:content-[''] after:block after:w-0 after:h-1 after:bg-gradient-to-r after:from-red-500 after:to-yellow-500 after:transition-all after:duration-500 hover:after:w-full">
                  {service.title}
                </p>

                <p className="text-gray-300 sm:text-base text-xs text-justify">{service.description}</p>
              </div>
            </div>
          ))}
        </main>
      </div>
    </div>
  );
};
