import Footer from "../comman/Footer";
import Header from "../comman/Header";
import About from "./About";
import { ContactUs } from "./ContactUs";
import { Events } from "./Events";
import { Feature } from "./Feature";
import { HeroSection } from "./HeroSection";
import OurPlatform from "./OurPlatform";
import ReviewSection from "./ReviewSection";
import { Service } from "./Service";
import { TrustedCompanies } from "./TrustedCompanies";

export const Home = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <TrustedCompanies/>
      <About />
      <Service />
      <Feature />
      {/* <Events/> */}
      <OurPlatform />
      <ContactUs/>
      <ReviewSection />
      
    
      <Footer />
    </>
  );
};
