import React from "react";

export const Feature = () => {
  const features = [
    {
      title: "Expert Franchise Guides",
      description:
        "Years of experience and a network of successful franchise owners.",
      imgSrc: "https://www.svgrepo.com/show/530438/ddos-protection.svg",
      link: null,
    },
    {
      title: "Tailored Suggestions",
      description:
        "Customized franchise options based on your goals.",
      imgSrc: "https://www.svgrepo.com/show/530442/port-detection.svg",
      link: null,
    },
    {
      title: "Global Franchise Network",
      description:
        "Access to opportunities worldwide.",
      imgSrc: "https://www.svgrepo.com/show/530444/availability.svg",
      link: null,
    },
    {
      title: "Complete Support",
      description:
        "Ongoing help with legal, operations, and finances.",
      imgSrc: "https://www.svgrepo.com/show/530440/machine-vision.svg",
      link: null,
    },
    {
      title: "Proven Marketing Support",
      description:
        "Access to effective marketing tools and strategies designed to help your franchise thrive.",
      imgSrc: "https://www.svgrepo.com/show/530450/page-analysis.svg",
      link: null,
    },
    {
      title: "Flexible Investment Options",
      description:
        "We offer a range of franchise opportunities to suit various budgets and financial goals.",
      imgSrc: "https://www.svgrepo.com/show/530453/mail-reception.svg",
      link: null,
    },
  ];
  

  return (
    <div className="bg-black px-4  sm:px-4 lg:px-0 py-10">
  <div id="features" className="mx-auto max-w-6xl">
    <div className="max-w-3xl mx-auto lg:text-center">
      <h2 id="about-franchise" className="relative text-center pb-4 font-display text-3xl font-bold tracking-tight text-gray-100 lg:text-3xl inline-block">
      Our Best Features
        <span className="absolute left-1/2 bottom-0 h-1 mb-2 w-full transform -translate-x-1/2 bg-gradient-to-r from-[#184277] to-yellow-900"></span>
      </h2>
      <p className="lg:text-center text-gray-300  text-sm leading-7 text-primary-500">
      At FinDocs, we do more than just connect you with a franchise. We help you through every step, from finding the right one to making it a success.
      </p>
    </div>
    <ul className="lg:mt-10 mt-5 grid grid-cols-1 gap-6 text-center text-slate-700 md:grid-cols-3">
      {features.map((feature, index) => (
        <li key={index}
        className="w-full text-center text-sm font-semibold text-slate-100 p-6 bg-gradient-to-b from-[#184277] to-transparent border border-transparent shadow-md shadow-slate-900/5 rounded-lg flex flex-col justify-center relative group transform transition-transform duration-500 hover:scale-105 hover:rotate-3 hover:rotate-x-6 hover:rotate-y-6 hover:shadow-xl hover:shadow-[#184277]">
          <img
            src={feature.imgSrc}
            alt={feature.title}
            className="mx-auto h-20 w-20"
          />
          {feature.link ? (
            <a href={feature.link} className="group">
              <h3 className="mt-3 font-display text-lg font-medium group-hover:text-primary-500">
                {feature.title}
              </h3>
              <p className="mt-1.5 text-sm leading-6 ">
                {feature.description}
              </p>
            </a>
          ) : (
            <>
              <h3 className="mt-3  font-display text-lg font-medium">{feature.title}</h3>
              <p className="mt-1.5 text-xs leading-6 text-secondary-500">
                {feature.description}
              </p>
            </>
          )}
        </li>
      ))}
    </ul>
  </div>
</div>

  );
};
