import "./App.css";
import { BrowserRouter,Routes, Route} from "react-router-dom";
import { Home } from "./Finadocs/page/Home";
import { Login } from "./Finadocs/AuthenticationAccount/Login";

function App() {
  return (
    <>
       <BrowserRouter >
      <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/login" element={<Login />}/>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
