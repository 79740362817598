import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="bg-gray-900">
      <nav className="relative shadow  bg-white-900/20">
        <div className="container px-6 py-3 mx-auto lg:flex">
          <div className="flex items-center justify-between pb-2">
            <Link to="/">
              <img className="w-auto h-10" src="/finadocslogo.png" alt="finadocs Logo" />
            </Link>
            <div className="flex lg:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="text-gray-100 hover:text-gray-600 focus:outline-none pl-4"
                aria-label={isOpen ? "Close menu" : "Open menu"}
              >
                {isOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div
            className={`absolute inset-x-0 bg-white-900/20 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center lg:justify-between ${
              isOpen ? "translate-x-0 opacity-100" : "opacity-0 -translate-x-full"
            }`}
          >
            <div className="flex flex-col lg:flex-row lg:mx-10 lg:py-0">
              <Link
                to="/"
                className="px-2.5 cursor-pointer py-2 text-sm  w-20 text-center text-gray-100 transition-colors duration-300 transform rounded-lg hover:bg-gray-800 lg:mx-2"
              >
                Home
              </Link>
              <a
                href="#about-us"
                className="px-2.5 cursor-pointer py-2 text-sm  w-32 text-center text-gray-100 transition-colors duration-300 transform rounded-lg hover:bg-gray-800 lg:mx-2"
              >
                About Us
              </a>
              <a
                href="#about-franchise"
                className="px-2.5 cursor-pointer py-2 text-sm  w-36 text-center text-gray-100 transition-colors duration-300 transform rounded-lg hover:bg-gray-800 lg:mx-2"
              >
                About Franchise
              </a>
              <a
                href="#our-clients"
                className="px-2.5 cursor-pointer py-2 text-sm  w-32  text-center text-gray-100 transition-colors duration-300 transform rounded-lg hover:bg-gray-800 lg:mx-2"
              >
                Our Clients
              </a>
              <a
                href="#our-services"
                className="px-2.5 cursor-pointer py-2 text-sm  w-32 text-center text-gray-100 transition-colors duration-300 transform rounded-lg hover:bg-gray-800 lg:mx-2"
              >
                Our Services
              </a>
            </div>
            <div className="relative mt-4 lg:mt-0">
              <Link to="/login">
                <button className="mt-2 py-[6px] w-24 text-gray-800 bg-white border border-transparent rounded-lg hover:bg-gray-200 focus:outline-none focus:ring focus:ring-blue-300 text-sm font-semibold">
                  Login
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default Header;
