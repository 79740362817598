import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "100+ Franchise Opportunities",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Trusted by Entrepreneurs Around the World.",
    icon: LockClosedIcon,
  },
  {
    name: "Personalized Franchise Suggestions",
   
    icon: ServerIcon,
  },
];

export default function About() {
  return (
    <div className="overflow-hidden bg-black py-6 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="relative p-[2px] lg:text-center lg:mb-10 mb-2 mt-2  rounded-lg shadow-sm overflow-hidden">     
          <h2 id="about-us" className="relative lg:text-center pb-4 font-display text-2xl font-bold tracking-tight text-gray-100 lg:text-3xl inline-block">
          Who We Are
        <span className="absolute left-1/2 bottom-0 h-1 mb-2 w-full transform -translate-x-1/2 bg-gradient-to-r from-[#184277] to-yellow-900"></span>
      </h2>
          
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 gap-x-8 sm:items-center">
          <div>
            <p className="mt-2 leading-8 text-gray-300 text-sm text-justify">
            FinDocs is a top franchise help platform that connects people with the best franchise opportunities. Whether you want to start your first franchise or grow your business, FinDocs gives you the advice, market knowledge, and expert help you need to succeed.
            </p>
            <dl className="mt-4 space-y-4 text-base leading-7 text-gray-300">
              <div className="">
                <h1 className="font-semibold text-lg capitalize text-white">
                  Key Point
                </h1>
              </div>   
              <ul className="mt-10 space-y-3 text-base leading-7 text-gray-300 list-disc pl-6">
                {features.map((feature) => (
                  <li key={feature.name} className="relative pl-3">
                    <dt className="inline font-medium text-white text-sm">
                      {feature.name}
                    </dt>
                    {/* <dd className="inline text-[13px] ml-2">
                      {feature.description}
                    </dd> */}
                  </li>
                ))}
              </ul>
            </dl>
          </div>
          {/* Image section */}
          <div className="flex justify-center lg:justify-end">
            <img
              alt="Product screenshot"
              src="/business.jpg"
              className="w-full max-w-[36rem] rounded-xl shadow-xl ring-1 ring-white/10 lg:w-[36rem]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
