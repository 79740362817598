import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
const trustedCompanies = [
  {
    title: "Absolutely recommended!",
    img: "https://tailwindui.com/plus/img/logos/transistor-logo-white.svg",
  },
  {
    title: "Absolutely recommended!",
    img: "https://tailwindui.com/plus/img/logos/reform-logo-white.svg",
  },
  {
    title: "Absolutely recommended!",
    img: "https://tailwindui.com/plus/img/logos/tuple-logo-white.svg",
  },
  {
    title: "Absolutely recommended!",
    img: "https://tailwindui.com/plus/img/logos/statamic-logo-white.svg",
  },
  {
    title: "Absolutely recommended!",
    img: "https://tailwindui.com/plus/img/logos/tuple-logo-white.svg",
  },
  {
    title: "Absolutely recommended!",
    img: "https://tailwindui.com/plus/img/logos/statamic-logo-white.svg",
  },
  {
    title: "Absolutely recommended!",
    img: "https://tailwindui.com/plus/img/logos/tuple-logo-white.svg",
  },
];
export const TrustedCompanies = () => {
  return (
    <>
 <div className="py-10 bg-[#041224] sm:px-4 lg:px-0 px-4">
  <div className="p-[2px] rounded-lg shadow-sm overflow-hidden">
    <div className="relative z-10 sm:p-6 rounded-lg">
      <Swiper
        spaceBetween={30}
        loop={true}
        grabCursor={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        modules={[Autoplay]}
        className="w-full"
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 2, 
          },
          768: {
            slidesPerView: 3, 
          },
          1024: {
            slidesPerView: 3, 
          },
          1280: {
            slidesPerView: 4, 
          },
        }}
      >
        {trustedCompanies.map((items, index) => (
          <SwiperSlide key={index}>
            <div className="flex justify-between items-center gap-10">
              <img
                alt="Transistor"
                src={items.img}
                className="object-contain object-left w-36 h-20 transition-all duration-300 ease-in-out filter grayscale hover:grayscale-0"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </div>
</div>



    </>
  );
};
