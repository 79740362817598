import React from "react";

const statistics = [
  { value: "45K+", label: "Users" },
  { value: "78K+", label: "Downloads" },
  { value: "50+", label: "Products" },
  { value: "100+", label: "Successful Franchise Matches" },
];

const OurPlatform = () => {
  return (
    <div className="bg-black">
      <div className=" max-w-7xl mx-auto sm:px-10 px-4 lg:pt-10 lg:pb-20  py-4">
        <div className="sm:text-center mb-5">
        <h2 id="platform" className="relative text-center pb-4 font-display text-2xl font-bold tracking-tight text-gray-100 sm:text-3xl inline-block">
        Our Platform's Impact
            <span className="absolute left-1/2 bottom-0 h-1 mb-2 w-full transform -translate-x-1/2 bg-gradient-to-r from-[#184277] to-yellow-900"></span>
          </h2>
        </div>
        <ul className="grid lg:grid-cols-4 lg:mb-4 mb-2 sm:grid-cols-2 grid-cols-1 gap-5 text-redis-neutral-800">
          {statistics.map((stat, index) => (
            <li
              key={index}
              className="w-full text-center text-sm font-semibold text-slate-900 p-6 bg-gradient-to-b from-[#184277] to-transparent border border-transparent shadow-md shadow-slate-900/5 rounded-lg flex flex-col justify-center relative group transform transition-transform duration-500 hover:scale-105 hover:rotate-3 hover:rotate-x-6 hover:rotate-y-6 hover:shadow-xl hover:shadow-[#184277]"
              style={{ perspective: "1000px" }}
            >
              <span className="mb-1 text-gray-200  lg:text-3xl text-xl ">
                {stat.value}
              </span>
              <span className="text-center text-gray-300  text-sm ">
                {stat.label}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OurPlatform;
